import {
	ethers
} from "ethers";
import Web3Modal from "web3modal"
import config from "./config/file" 
const { chainId, rpc } = config

import { Notification } from 'element-ui'

import store from '@/store/index'

const providerOptions = {
	// walletconnect: {
    //     package: WalletConnectProvider, // 使用 WalletConnect
    //     options: {
    //         infuraId: rpc, // 或者其他 RPC URL
    //     },
    // },
};

const web3Modal = new Web3Modal({
	network: "mainnet", // optional
	cacheProvider: true, // optional
	providerOptions // required
});

let instance = null;
let provider = null;
let signer = null;

// 获取钱包地址
export async function getWalletAddress() {
	if (instance) removeListener()
	instance = await web3Modal.connect();
	provider = new ethers.providers.Web3Provider(instance);
	signer = provider.getSigner();
	createListener()
	// 正式网络引导用户切换至 BSC 网络
	if(chainId == '56')await checkChainId()
	const address = signer.getAddress()
	return address
}

// 签名
export async function getSign(message) {
	if (signer) {
		const timestamp = Date.parse(new Date()) / 1000;
		const signature = await signer.signMessage(message + timestamp)
		return signature;
	}
}

// 检查链
async function checkChainId(){
	const obj = {
		method: 'wallet_addEthereumChain',
		params: [{
			chainId: '0x38',
			chainName: 'BSC',
			nativeCurrency: {
				name: 'BNB',
				symbol: 'BNB',
				decimals: 18,
			},
			rpcUrls: ['https://bsc-dataseed.binance.org/'],
			blockExplorerUrls: ['https://bscscan.com/'],
		}]
	}
	try {
		await instance.request(obj)
	} catch (error) {
		if(error.code == 4902){
			try {
				await instance.request(obj)
			} catch (err) {
				return
			}
		}else if(error.code == 4001){
			return
		}
	}
	
}

// 移除已有监听
function removeListener() {
	instance.off('accountsChanged', onAccountsChanged)
	instance.off('chainChanged', onChainChanged)
	instance.off('connect', onConnect)
	instance.off('disconnect', onDisconnect)
}

// 创建监听
function createListener() {
	instance.on("accountsChanged", onAccountsChanged);
	instance.on("chainChanged", onChainChanged);
	instance.on("connect", onConnect);
	instance.on("disconnect", onDisconnect);
}

// 账户改变
function onAccountsChanged(accounts) {
	store.dispatch('dapp/init')
}

// Chain改变
function onChainChanged(chainId) {
	store.dispatch('dapp/init')
}

function onConnect(info) {
	console.log(info);
}

function onDisconnect(error) {
	console.log(error);
}

export class Web3Init {
	constructor({
		artifact = null,
		address = null,
		abi = null
	}) {
		this.meta = this.account = this.signer = this.web3 = null;
		this.artifact = artifact;
		//以下2种情况对应，后端开发人员直接给出合约地址与合约abi的情况（比如只给了json文件的abi部分）
		this.address = address;
		this.abi = abi;
		// 链接的url地址，有啥用暂时不清楚,在main.js中设置
		this.url = this.constructor.prototype.constructor.url;
	}
	async init() {
		this.web3 = provider
		await this.start()
		return this;
	}
	async start() {
		const {
			web3
		} = this;
		try {
			// get contract instance
			const networkId = (await web3.getNetwork()).chainId;

			if (this.address === null) {
				const deployedNetwork = this.artifact.networks[networkId];
				this.address = deployedNetwork.address;
			}
			if (this.abi === null) {
				this.abi = this.artifact.abi;
			}
			this.signer = web3.getSigner()
			this.meta = new ethers.Contract(
				this.address,
				this.abi,
				web3
			).connect(this.signer);
			this.account = await this.signer.getAddress();
		} catch (error) { }
	}
}