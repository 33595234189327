<template>
  <div id="app" @click="hideLanuage">
    <img src="./assets/images/bg.png" class="bg" />

    <nav-bar ref="navbar" @scroll="scrollTo" :opacity="opacity"></nav-bar>

    <div v-show="navCur < 7">
      <div id="Home"></div>
      <top-head></top-head>

      <div id="about"></div>
      <about-us></about-us>

      <div id="advantage"></div>
      <advant-age></advant-age>

      <div id="target"></div>
      <OurTarget></OurTarget>

      <div id="coin"></div>
      <coin-knowledge></coin-knowledge>

      <div id="news"></div>
      <news-list></news-list>

      <div id="partner"></div>
      <our-partner></our-partner>

      <bot-bar></bot-bar>
    </div>

    <div class="pos-rel">
      <buy-node v-if="navCur == 7"></buy-node>
      <invite-teacher v-if="navCur == 8"></invite-teacher>
      <project-desc v-if="navCur == 9"></project-desc>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from "@/utils";
import NavBar from "./components/NavBar.vue";
import TopHead from "./components/TopHead.vue";
import AboutUs from "./components/AboutUs.vue";
import AdvantAge from "./components/AdvantAge.vue";
import OurTarget from "./components/OurTarget.vue";
import CoinKnowledge from "./components/CoinKnowledge.vue";
import NewsList from "./components/NewsList.vue";
import OurPartner from "./components/OurPartner.vue";
import BotBar from "./components/BotBar.vue";
import ProjectDesc from "./components/ProjectDesc.vue";
import InviteTeacher from "./components/InviteTeacher.vue";
import BuyNode from "./components/BuyNode.vue";
export default {
  name: "App",
  components: {
    NavBar,
    TopHead,
    AboutUs,
    AdvantAge,
    OurTarget,
    CoinKnowledge,
    NewsList,
    OurPartner,
    BotBar,
    ProjectDesc,
    InviteTeacher,
    BuyNode
  },
  data() {
    return {
      opacity: 0,
    };
  },
  computed: {
    navCur() {
      return this.$store.state.user.navCur;
    },
  },
  async mounted() {
    this.$store.dispatch("user/getWebInfo");

    // 记录邀请码
    const ref = getUrlParam(this.$config.inviteKey);
    this.$store.commit("user/SET_REF", ref);

    // // 初始化Dapp相关
    this.$store.dispatch("dapp/init");

    window.onscroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 300) {
        this.opacity = 1;
      } else {
        this.opacity = Math.floor((scrollTop / 300) * 100) / 100;
      }
    };
  },
  methods: {
    scrollTo(data) {
      if (data) {
        this.$nextTick(() => {
          const element = document.getElementById(data);
          element.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
    hideLanuage() {
      this.$refs.navbar.hideLanuage();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/index.scss";
.bg {
  width: 100vw;
  height: auto;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
