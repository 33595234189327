import { Web3Init } from '../web3-init.js'
import file from './file.js';
Web3Init.url = file.rpc;
export default {
	async erc20Init(address) {
		let {
			erc20_abi,
		} = file;
		let instance = new Web3Init({
			artifact:null,
			address:address,
			abi:erc20_abi
		});
		let obj = await instance.init();
		return obj;
	}
}