<template>
  <div>
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2" />
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
      {{ $t("新闻") }}
    </div>

    <div class="main-padding">
      <div class="flex flex-wrap">
        <div
          class="news-tab flex-shrink"
          :class="current == index ? 'news-active' : ''"
          v-for="(item,index) in newsData"
          :key="index"
          @click="tabsClick(index)"
        >
          {{ $t(item.cateName) }}
        </div>
      </div>
      <!-- <div class="mobile tabshead" id="NewsList">
        <div class="flex">
          <div
            class="news-tab flex-shrink"
            :class="current == index ? 'news-active' : ''"
            v-for="(item,index) in newsData"
            :key="index"
            @click="tabsClick(index)"
          >
            {{ $t(item.cateName) }}
          </div>
        </div>
      </div> -->

      <div class="flex justify-between flex-wrap pt-30" v-if="newsData">
        <div
          class="new-card cur ani-move"
          v-for="(item, index) in newsData[current].list"
          :key="index"
          @click="delayFun(jumpLink, item.link)"
          v-animate-onscroll.repeat="'animate__animated animate__slideInUp'"
        >
          <div class="flex align-center">
            <div class="news-title flex-1">{{ $t(item.title) }}</div>

            <img src="../assets/images/arrow-right.png" class="img-20 ml-10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/assets/news.json";
import PerfectScrollbar from "perfect-scrollbar";
export default {
  name: "NewsList",
  data() {
    return {
      current: 0,
      newsData: null,
    };
  },
  mounted() {
    this.newsData = list;
    // const scrollContainer = document.querySelector("#targetElement");

    // const ps = new PerfectScrollbar(scrollContainer, {
    //   suppressScrollX: false, // enable horizontal scroll
    //   suppressScrollY: true, // enable vertical scroll
    //   maxScrollbarLength: 480,
    // });

    // // add mouse wheel event listener
    // scrollContainer.addEventListener("wheel", (e) => {
    //   e.preventDefault();
    //   const delta = Math.abs(e.wheelDelta) > 0 ? e.wheelDelta : -e.deltaY;
    //   scrollContainer.scrollLeft -= delta / 4; // adjust scrolling speed
    // });
  },
  methods: {
    tabsClick(index) {
      this.current = index;
    },
    jumpLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss">
.news-tab {
  font-weight: bold;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.2rem;
  padding: 0 0.3rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #00e36f;
    color: #131513;
  }
}
.news-active {
  background-color: #00e36f;
  color: #131513;
}

.new-card {
  min-width: 330rpx;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: #1f211f;
  margin-bottom: 0.1rem;
  font-size: 0.16rem;
  .news-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
}
@media (min-width: 768px) {
  .new-card {
    width: 33vw;
  }
}
@media (max-width: 768px) {
  .new-card {
    width: 40vw;
  }
}
</style>
