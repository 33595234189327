import config from '@/config';
import http from '@/utils/request';
const { getLang } = config

const state = {
    ref: null,
    lang: getLang(),
    webInfo: {},
    userInfo: {},
    navCur:0
}

const mutations = {
    SET_REF(state, payload) {
        state.ref = payload
    },
    SET_LANG(state, payload) {
		state.lang = payload
	},
    SET_NAVCUR(state, payload){
        state.navCur = payload
    },
    SET_WEBINFO(state, payload){
        state.webInfo = payload
    },
    SET_USERINFO(state, payload){
        state.userInfo = payload
    }
}

const actions = {
    getWebInfo({commit}){
        http('/api/web/config').then(res=>{
            commit('SET_WEBINFO', res)
        })
    },
    getUserInfo({rootState, state, commit}){
        http('/api/web/login',{
            address: rootState.dapp.address,
			p_id: state.ref
        }, "POST").then(res=>{
            commit('SET_USERINFO', res)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

