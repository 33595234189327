const getters = {
    address: state => {
        let str = state.dapp.address
        if (str) {
            return str.slice(0, 6) + '****' + str.slice(str.length - 4)
        }else{
            return ''
        }
    },
    erc20: state => state.dapp.erc20,
    navCur: state => state.user.navCur,
    lang: state => state.user.lang,
    webInfo: state => state.user.webInfo,
    userInfo: state => state.user.userInfo
}
export default getters