import BigNumber from "bignumber.js";
import { Notification } from 'element-ui'
import { $t } from '@/main'

/**
 * @description 大数字转小数字
 * @param { String | Number } bigNum - 大数字
 * @returns { Number } 展示用小数字
 */
export function bigToSmall(bigNum) {
    return new BigNumber(bigNum).div('1e18').dp(4).toNumber()
}

/**
 * @description 小数字转大数字
 * @param { String | Number } smallNum - 小数字
 * @returns { String } 合约用大数字
 */
export function smallToBig(smallNum) {
    return new BigNumber(smallNum).times('1e18').toString()
}

/**
 * @description 大数字之间比较大小
 * @param { String | Number } a
 * @param { String | Number } b 
 * @returns { Number } a>b=1 a=b=0 a<b=-1
 */
export function compare(a, b) {
    let num1 = new BigNumber(a)
    let num2 = new BigNumber(b)
    return num1.comparedTo(num2)
}

/**
 * 普通数字乘法精度计算
 * @param {Number|String} a - 第一个数字 
 * @param {Number|String} b - 第二个数字
 * @returns {Number} a * b
 */
export function computedMul(a, b){
    var c = 0,
        d = a.toString(),
        e = b.toString();
    try {
        c += d.split(".")[1].length
    } catch (f) {}
    try {
        c += e.split(".")[1].length
    } catch (f) {}
    return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c)
}

/**
 * 普通数字除法精度计算
 * @param {Number|String} a - 第一个数字 
 * @param {Number|String} b - 第二个数字
 * @returns {Number} a / b 保留两位小数
 */
export function computedDiv(a, b){
    var c, d, e = 0,
        f = 0;
    try {
        e = a.toString().split(".")[1].length
    } catch (g) {}
    try {
        f = b.toString().split(".")[1].length
    } catch (g) {}
    return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), computedMul(c /
        d, Math.pow(10, f - e))
}


/**
 * 普通数字加法精度计算
 * @param {Number|String} a - 第一个数字 
 * @param {Number|String} b - 第二个数字
 * @returns {Number} a + b
 */
export function computedAdd(a, b) {
    var c, d, e;
    try {
        c = a.toString().split(".")[1].length
    } catch (f) {
        c = 0
    }
    try {
        d = b.toString().split(".")[1].length
    } catch (f) {
        d = 0
    }
    return e = Math.pow(10, Math.max(c, d)), (computedMul(a, e) + computedMul(b, e)) / e
}

/**
 * 普通数字减法精度计算
 * @param {Number|String} a - 第一个数字 
 * @param {Number|String} b - 第二个数字
 * @returns {Number} a - b
 */
export function computedSub(a, b){
    var c, d, e;
    try {
        c = a.toString().split(".")[1].length
    } catch (f) {
        c = 0
    }
    try {
        d = b.toString().split(".")[1].length
    } catch (f) {
        d = 0
    }
    return e = Math.pow(10, Math.max(c, d)), (computedMul(a, e) - computedMul(b, e)) / e
}

/**
 * @description 复制字符串
 * @param { String } content - 复制内容 
 */
export function copyLink(content, message) {
    document.body.style.overflow = 'hidden';
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(content).then(() => {
            // Notification({
            //     title: 'Success',
            //     type: 'success',
            //     message: $t('已复制')
            // });
        });
        document.body.style.overflow = 'auto';
    } else {
        // 创建text area
        const textArea = document.createElement("textarea");
        textArea.value = content;
        // 使text area不在viewport，同时设置不可见
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((resolve, reject) => {
            // 执行复制命令并移除文本框
            document.execCommand("copy")
                ? resolve()
                : reject(new Error("出错了"));
            textArea.remove();
        })
            .then(() => {
                // Notification({
                //     title: 'Success',
                //     type: 'success',
                //     message: $t('已复制')
                // });
            })
            .catch(() => { });
    }
}

/**
 * @description 获取地址栏拼接的指定参数
 * @param { String } key - 参数名 
 * @returns { String } 参数值
 */
export function getUrlParam(key){
    const url = new URL(window.location.href)
    return url.searchParams.get(key)||null
}