/**
 * 请求地址
 */
let baseUrl;

if (process.env.NODE_ENV === 'development') baseUrl = 'https://www.613protocol.com';// 本地环境
// else if (process.env.NODE_ENV === 'production') baseUrl = ``;// 线上环境
else if (process.env.NODE_ENV === 'production') baseUrl = `${window.location.protocol}//${window.location.host}`;// 线上环境

/**
 * 邀请
 */
// 参数名
const inviteKey = 'ref'
// 邀请链接
const inviteUrl = `${window.location.protocol}//${window.location.host}/?${inviteKey}=`

/**
 * 请求头常量
 */
// Token
const tokenKey = 'Authorization'
// Token令牌
const bearerToken = 'Bearer '
// 多语言
const langKey = 'Lang'
// 钱包地址
const addressKey = 'Address'

export default {
    baseUrl,
    inviteUrl,
    tokenKey,
    bearerToken,
    langKey,
    addressKey
};