<template>
  <div>
    <div
      class="navbar main-padding pt-20 pb-20"
      :style="{ backgroundColor: `rgba(14, 16, 14, ${opacity})` }"
    >
      <div class="flex justify-between align-center">
        <img src="@/assets/logo.png" class="logo cur" @click="menuChange(0)" />

        <div class="flex align-center">
          <div
            class="navbar-box flex align-center cur"
            @click.stop="lanuageShow = !lanuageShow"
          >
            <img src="@/assets/images/lanuage.png" class="img-30" />
            <div class="ml-8 mr-10">{{ locals[current].name }}</div>
            <img src="@/assets/images/arrow.png" class="arrow" />
            <div
              class="navbar-picker size-16"
              :class="lanuageShow ? 'navbar-picker-show' : ''"
              @click.stop
            >
              <div
                class="size-16 text-center mb-24"
                :class="current == index ? 'color-green' : ''"
                v-for="(item, index) in locals"
                :key="index"
                @click="tabsClick(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="ani-move navbar-btn ml-20" @click="connect">
            {{ address || $t("链接钱包") }}
          </div>

          <img
            src="@/assets/images/closeMenu.png"
            class="img-42 ml-20 mobile"
            v-show="show"
            @click="showChange"
          />
          <img
            src="@/assets/images/menu.png"
            class="img-42 ml-20 mobile"
            v-show="!show"
            @click="showChange"
          />
        </div>
      </div>

      <div class="flex justify-between mt-30 pc">
        <div
          class="size-14 text-bold pos-rel flex flex-direction align-center"
          v-for="(item, index) in tabs"
          :key="index"
          @click="menuChange(index)"
        >
          <div class="cur">{{ item.name }}</div>
          <div
            class="navbar-line"
            :class="navCur == index ? 'navbar-line-active' : ''"
          ></div>
        </div>
      </div>
    </div>

    <div class="popup flex justify-end" @click="show = false" v-if="show">
      <div class="popupBox" @click.stop>
        <div
          class="menu-item flex align-center"
          :style="{ backgroundColor: navCur == index ? '#00E36F' : '#0E100E' }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="menuChange(index)"
        >
          <img
            :src="navCur == index ? item.icon : item.iconChoose"
            class="img-30"
          />
          <div
            class="size-20 ml-20"
            :style="{ color: navCur == index ? '#181818' : '#FFFFFF' }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavBar",
  props: {
    opacity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      lanuageShow: false,

      locals: [
        { name: "English", value: "en" },
        { name: "繁体中文", value: "zh_TW" },
        { name: "简体中文", value: "zh" },
      ],
    };
  },
  computed: {
    ...mapGetters(["address", "navCur"]),
    current() {
      const lang = this.$store.state.user.lang;
      const index = this.locals.findIndex((item) => item.value == lang);
      return index;
    },
    tabs() {
      return [
        {
          name: this.$t("首页"),
          value: "Home",
          icon: require("@/assets/images/menu1.png"),
          iconChoose: require("@/assets/images/menu1-1.png"),
        },
        {
          name: this.$t("关于我们"),
          value: "about",
          icon: require("@/assets/images/menu2.png"),
          iconChoose: require("@/assets/images/menu2-2.png"),
        },
        {
          name: this.$t("亮点优势"),
          value: "advantage",
          icon: require("@/assets/images/menu3.png"),
          iconChoose: require("@/assets/images/menu3-3.png"),
        },
        {
          name: this.$t("发展线路"),
          value: "target",
          icon: require("@/assets/images/menu4.png"),
          iconChoose: require("@/assets/images/menu4-4.png"),
        },
        {
          name: this.$t("代币经济学"),
          value: "coin",
          icon: require("@/assets/images/menu5.png"),
          iconChoose: require("@/assets/images/menu5-5.png"),
        },
        {
          name: this.$t("新闻"),
          value: "news",
          icon: require("@/assets/images/newsChoose.png"),
          iconChoose: require("@/assets/images/news.png"),
        },
        {
          name: this.$t("合作伙伴"),
          value: "partner",
          icon: require("@/assets/images/menu8.png"),
          iconChoose: require("@/assets/images/menu8-8.png"),
        },
        {
          name: this.$t("节点"),
          value: "",
          icon: require("@/assets/images/menu6.png"),
          iconChoose: require("@/assets/images/menu6-6.png"),
        },
        {
          name: this.$t("志愿者招募"),
          value: "",
          icon: require("@/assets/images/menu7.png"),
          iconChoose: require("@/assets/images/menu7-7.png"),
        },
        {
          name: this.$t("项目介绍"),
          value: "",
          icon: require("@/assets/images/projectChoose.png"),
          iconChoose: require("@/assets/images/project.png"),
        },
      ];
    },
  },
  methods: {
    connect() {
      this.$store.dispatch("dapp/init");
    },
    tabsClick(index) {
      this.lanuageShow = false;
      if (this.current == index) return;
      const lang = this.locals[index].value;
      this.$i18n.locale = lang;
      this.$config.setLang(lang);
      this.$store.commit("user/SET_LANG", lang);
    },
    menuChange(index) {
      this.$store.commit("user/SET_NAVCUR", index);
      setTimeout(() => {
        this.show = false;
        this.$emit("scroll", this.tabs[this.navCur].value);
      }, 200);
    },
    showChange() {
      this.show = !this.show;
    },
    hideLanuage() {
      this.lanuageShow = false;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}
.logo {
  width: 0.54rem;
  height: 0.46rem;
}
.arrow {
  width: 0.12rem;
  height: 0.06rem;
}
.navbar-box {
  height: 0.42rem;
  border-radius: 0.21rem;
  padding: 0 0.14rem 0 0.06rem;
  font-size: 0.14rem;
  border: 1px solid #ffffff;
  position: relative;

  .navbar-picker {
    font-weight: bold;
    width: 100%;
    border-radius: 0.2rem;
    padding-top: 0.24rem;
    background-color: #1f211f;
    position: absolute;
    left: 0;
    top: 0.5rem;
    z-index: 10;
    transition: all 0.2s;
    transform-origin: 0 0;
    transform: scaleY(0);
  }

  .navbar-picker-show {
    transform: scaleY(1) !important;
  }
}

.navbar-btn {
  background-color: #00e36f;
  color: #131513;
  font-weight: bold;
}

.navbar-line {
  width: 0;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
}

.navbar-line-active {
  bottom: -10px !important;
  width: 50% !important;
}

.navbar-popBox {
  width: 410rpx;
  height: 100vh;
  padding-top: 116rpx;

  .navbar-pop {
    width: 410rpx;
    height: calc(100vh - 116rpx);
    background-color: #0e100e;
    border-radius: 40rpx 0 0 40rpx;
    padding: 40rpx 20rpx 0 20rpx;

    .navbar-item {
      height: 80rpx;
      border-radius: 20rpx;
      padding-left: 30rpx;
      font-size: 26rpx;
      margin-bottom: 10rpx;
    }
  }
}
.navbar-btn {
  height: 0.42rem;
  line-height: 0.42rem;
  border-radius: 0.21rem;
  padding: 0 0.18rem;
  font-size: 0.14rem;
}

@media (min-width: 768px) {
}

@media (max-width: 768px) {
}

.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 1.14rem;
  .popupBox {
    width: 3rem;
    height: calc(100vh - 1.34rem);
    padding: 0.4rem 0.2rem;
    border-radius: 0.4rem 0 0 0.4rem;
    transform: translateX(0.4rem);
    animation: show 0.3s linear forwards;
    background-color: #0e100e;
    .menu-item {
      height: 0.6rem;
      border-radius: 0.2rem;
      padding: 0 0.3rem;
      margin-bottom: 0.1rem;
    }
  }
}
@keyframes show {
  from {
    transform: translateX(3rem);
  }

  to {
    transform: translateX(0);
  }
}
</style>