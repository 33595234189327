<template>
  <div class="teacher-page">
    <div class="teacher">
      <div class="text-center">
        <img src="../assets/images/img4.png" class="img-44" />
        <div class="size-32 text-bold">{{ $t("欢迎您的到来") }}</div>
        <div class="size-20 color-9 mt-24">
          {{ $t("请留下您的信息，方便我们联系您") }}
        </div>
      </div>

      <div class="flex align-center size-20 mt-38">
        <div>{{ $t("姓名") }}</div>
        <div class="color-red">*</div>
      </div>
      <div class="teacher-inp mt-30 flex justify-between align-center">
        <input
          type="text"
          v-model="form.name"
          :placeholder="$t('请输入您的姓名')"
          class="size-18 flex-1 bg-none"
        />
      </div>

      <div class="flex align-center size-20 mt-38">
        <div>{{ $t("联系方式（邮箱）") }}</div>
        <div class="color-red">*</div>
      </div>
      <div class="teacher-inp mt-30 flex justify-between align-center">
        <input
          type="text"
          v-model="form.contact"
          :placeholder="$t('请输入您的邮箱号')"
          class="size-18 flex-1 bg-none"
        />
      </div>

      <div class="flex align-center size-20 mt-38">
        <div>{{ $t("钱包地址") }}</div>
        <div class="color-red">*</div>
      </div>
      <div class="teacher-inp mt-30 flex justify-between align-center">
        <input
          type="text"
          v-model="form.address"
          :placeholder="$t('请输入您的钱包地址')"
          class="size-18 flex-1 bg-none"
        />
      </div>

      <div class="flex align-center size-20 mt-38">
        <div>Telegram</div>
        <div class="color-red">*</div>
      </div>
      <div class="teacher-inp mt-30 flex justify-between align-center">
        <input
          type="text"
          v-model="form.telegraph"
          :placeholder="$t('请输入您的Telegraph账号')"
          class="size-18 flex-1 bg-none"
        />
      </div>

      <div class="flex align-center size-20 mt-38">
        <div>{{ $t("简历") }}</div>
        <div class="color-red">*</div>
      </div>
      <div
        class="teacher-inp mt-30 flex justify-between align-center"
      >
        <el-upload
          class="flex-center"
          name="upload"
          :action="uploadApi"
          :show-file-list="false"
          :limit="1"
          :on-success="onSuccess"
        >
          <div class="size-18" v-if="fileName">{{ fileName }}</div>
          <div class="size-18 color-9" v-else>
            {{ $t("点击上传您的简历（PPT、PSD格式）") }}
          </div>
        </el-upload>
      </div>

      <div class="donate-btn size-18 mt-40 ani-move" @click="delayFun(submit)">
        {{ $t("确认") }}
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/utils/request";
import config from "@/config";
const { baseUrl } = config;
export default {
  name: "InviteTeacher",
  data() {
    return {
      form: {
        address: "",
        name: "",
        contact: "",
        telegraph: "",
        vitae: "",
      },
      fileName: "",
    };
  },
  computed: {
    uploadApi() {
      return baseUrl + "/api/web/upload";
    },
  },
  methods: {
    onSuccess(e, file){
        this.form.vitae = e.url;
        this.fileName = file.name;
    },
    submit() {
      if (!this.form.name) return this.showToast(this.$t("请输入您的姓名"));
      if (!this.form.contact)
        return this.showToast(this.$t("请输入您的邮箱号"));
      if (!this.form.address)
        return this.showToast(this.$t("请输入您的钱包地址"));
      if (!this.form.telegraph)
        return this.showToast(this.$t("请输入您的Telegraph账号"));
      if (!this.form.vitae) return this.showToast(this.$t("请上传您的简历"));
      http("/api/web/lecturerRecruitment", this.form, "POST").then((res) => {
        this.showToast(this.$t("提交成功"),'success');
        this.form.name = "";
        this.form.contact = "";
        this.form.address = "";
        this.form.telegraph = "";
        this.form.vitae = "";
        this.fileName = "";
      });
    },
  },
};
</script>

<style lang="scss">
.teacher-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2.5rem 0 0.3rem 0;
}
.teacher {
  max-width: 90vw;
  width: 4.9rem;
  position: relative;
  .teacher-inp {
    width: 100%;
    height: 0.64rem;
    border-radius: 0.1rem;
    background-color: #1f211f;
    border: 1px solid #373937;
    padding: 0 0.3rem;
  }
  .donate-btn {
    font-weight: bold;
    background-color: #00e36f;
    color: #0e100e;
    text-align: center;
    width: 100%;
    height: 0.6rem;
    line-height: 0.6rem;
    border-radius: 0.3rem;
  }
  .donate-pop {
    max-width: 100vw;
    width: 5.2rem;
    padding: 0.3rem 0.35rem 0.35rem 0.35rem;
    border-radius: 0.4rem;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 0.2s;
    position: absolute;
    top: 1.2rem;
    left: -0.2rem;
    z-index: 1;
    transform: scaleY(0);
  }
}
.donate-rule:hover ~ .donate-pop {
  transform: scaleY(1) !important;
}
@media (max-width: 768px) {
}
</style>