import { getWalletAddress } from '@/dapp/web3-init'
import config from '@/config';
const { setAddress, usdt_address } = config
import dapp from "@/dapp";

const state = {
	address: null,
	erc20: null
}
const mutations = {
	SET_ADDRESS(state, payload) {
		state.address = payload
	},
	SET_ERC20(state, payload) {
		state.erc20 = payload
	}
}
const actions = {
	async init({ commit, dispatch }) {
		// 获取地址
		const address = await getWalletAddress()
		// 初始化ERC20
		const erc20 = await dapp.erc20.init(usdt_address)
		commit('SET_ERC20', erc20)
		// 保存地址
		setAddress(address)
		commit('SET_ADDRESS', address)
		// 登录获取信息
		dispatch('user/getUserInfo', null, {root: true})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
