<template>
  <div class="flex-center donate-page main-padding">
		<div class="flex-center flex-direction" style="width: 100%;" v-if="address">
			<div class="donate flex flex-direction align-center">
				<div class="code-box flex-center pt-20">
                    <qrcode :value="qrcodeStr" :size="220"></qrcode>
				</div>
				<div class="donate-box mt-40 text-center size-18">
					<div class="text-bold" style="color: #5A5C5A;">{{$t('地址')}}:</div>
					<div class="word-warp mt-20">{{webInfo.donate_address}}</div>
				</div>
				<div class="donate-inp mt-30 flex justify-between align-center">
					<div class="size-18">{{$t('捐赠金额')}}</div>
					<div class="text-bold size-24">{{webInfo.donate_amount}} USDT</div>
				</div>
				<div class="donate-btn size-18 mt-30 ani-move" v-if="is_donate==0" @click="delayFun(submit)" v-loading="loading">
					{{$t('确认捐赠')}}</div>
				<div class="donate-inp mt-30 size-24 color-9 flex-center" v-else>{{$t('已捐赠')}}</div>
				<div class="size-16 mt-30" style="color: #5A5C5A;">
					{{$t('节点预售须知')}} :<br>
					1、{{$t('必须有线下工作室；')}}<br>
					2、{{$t('具有3年以上团队管理经验，团队核心不少于3-5人；')}}<br>
					3、{{$t('接触并了解一定的区块链行业相关知识。')}}
				</div>
			</div>

			<div class="donate-card mt-70">
				<div class="left">
					<img src="../assets/images/img5.png" class="img-74 flex-shrink" />
					<div class="ml-30">
						<div class="size-32 text-bold">{{$t('邀请链接')}}</div>
						<div class="size-20 mt-20 word-warp">{{inviteUrl}}</div>
					</div>
				</div>
				<div class="flex align-center right">
					<div class="flex-shrink text-right">
						<div class="size-26 text-bold">{{userInfo.team_count}}</div>
						<div class="size-20 mt-10">{{$t('邀请人数')}}</div>
					</div>
					<div class="donate-invite size-18 ml-30 ani-move" @click="delayFun(copyId)">{{$t('复制链接')}}</div>
				</div>
			</div>
		</div>
		<image src="../assets/images/load.gif" mode="widthFix" class="load" v-else></image>
	</div>
</template>

<script>
import BigNumber from "bignumber.js";
import config from "@/config";
const { inviteUrl } = config;
import { mapGetters } from "vuex";
import http from "@/utils/request";
import { copyLink } from "@/utils";
import qrcode from "qrcode.vue";
export default {
  name: "BuyNode",
  components:{
    qrcode
  },
  data() {
    return {
      is_donate: 0,
      dappERC20: null,
      balance: 0,
      loading:false
    };
  },
  computed: {
    ...mapGetters(["address", "webInfo", "userInfo", "erc20"]),
    inviteUrl() {
      return `${inviteUrl}${this.userInfo.user_id}`;
    },
    qrcodeStr(){
        const webInfo = this.$store.state.user.webInfo
        if(webInfo.donate_address){
            return webInfo.donate_address
        }else{
            return ''
        }
    }
  },
  watch: {
    address: {
      async handler(newVal, oldVal) {
        if (newVal) {
            this.loadData()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    loadData() {
      http("/api/web/is_donate", {
          address: this.$store.state.dapp.address,
        })
        .then((res) => {
          this.is_donate = res.is_donate;
        });
    },
    async submit() {
      let amount = new BigNumber(this.webInfo.donate_amount).times("1e18");
      this.balance = await this.erc20.balanceOf()
      let balance = new BigNumber(this.balance);
      
      if (balance.comparedTo(amount) >= 0) {
        this.loading = true
        this.erc20
          .transfer(this.webInfo.donate_address, amount.toFixed(0))
          .then(async (result) => {
            await result.wait();
            this.showToast(this.$t("捐赠成功"),'success')
            this.is_donate = 1;
            this.loading = false
          })
          .catch((error) => {
            this.showToast(this.$t("捐赠失败"),'error')
            this.loading = false
          });
      } else {
        this.showToast(this.$t("余额不足"),'error')
      }
    },
    copyId() {
      copyLink(this.inviteUrl)
    },
  },
};
</script>

<style lang="scss">
.load {
  width: 1.12rem;
}

.donate-page {
		padding-top: 2.5rem;
		padding-bottom: .3rem;
	}

.donate-card {
  max-width: 90vw;
  width: 100%;
  padding: 0.4rem;
  background-color: #1f211f;
  border-radius: 0.4rem;

  .donate-invite {
    height: 0.6rem;
    line-height: 0.6rem;
    border-radius: 0.3rem;
    background-color: #00e36f;
    color: #0e100e;
    font-weight: bold;
    text-align: center;
  }
}

.donate {
  max-width: 90vw;
  width: 4.8rem;
  border-radius: 0.4rem;
  background-color: #1f211f;
  padding: 0.6rem 0.3rem 0.4rem 0.3rem;
  position: relative;

  .donate-rule {
    color: #5a5c5a;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    z-index: 1;
  }

  .code-box {
    width: 250px;
    height: 250px;
    border-radius: 0.2rem;
    background-color: #ffffff;

    .code {
      width: 220px;
      height: 220px;
    }
  }

  .donate-box {
    width: 100%;
    padding: 0.23rem 0.32rem;
    background-color: #181a18;
    border-radius: 0.2rem;
  }

  .donate-inp {
    width: 100%;
    height: 0.64rem;
    border-radius: 0.2rem;
    background-color: #2b2d2b;
    padding: 0 0.3rem;
  }

  .donate-btn {
    font-weight: bold;
    background-color: #00e36f;
    color: #0e100e;
    text-align: center;
    width: 100%;
    height: 0.6rem;
    line-height: 0.6rem;
    border-radius: 0.3rem;
  }

  .donate-pop {
    max-width: 100vw;
    width: 5.2rem;
    padding: 0.3rem 0.35rem 0.35rem 0.35rem;
    border-radius: 0.4rem;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 0.2s;
    position: absolute;
    top: 1.2rem;
    left: -0.2rem;
    z-index: 1;
    transform: scaleY(0);
  }
}

.donate-rule:hover ~ .donate-pop {
  transform: scaleY(1) !important;
}

@media (min-width: 768px) {
  .donate-card {
    display: flex;
    align-items: center;

    .left {
      display: flex;
      flex: 1;
    }

    .right {
      flex-shrink: 0;

      .donate-invite {
        width: 2rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .donate-page {
    padding-top: 100px !important;
    background-image: none !important;
  }

  .donate-card {
    .left {
      display: flex;
    }

    .right {
      margin-top: 0.3rem;

      .donate-invite {
        width: 100%;
      }
    }
  }
}
</style>