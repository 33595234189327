<template>
  <div class="main-padding introduce pos-rel">
    <div class="introduce-inp flex justify-between align-center">
      <input
        type="text"
        v-model="email"
        :placeholder="$t('请输入您的邮箱')"
        class="size-18 flex-1 introduce-input"
      />
      <div class="introduce-btn size-18 ani-move cur" @click="delayFun(submit)">
        {{ $t("领取资料") }}
      </div>
    </div>
    <div class="jieshoDetail mt-30">
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/平台介绍.png')"
      >
        <div class="title size-16">{{ $t("平台介绍") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/平台介绍.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/投资机构介绍.png')"
      >
        <div class="title size-16">{{ $t("投资机构介绍") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/投资机构介绍.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/协议前景.png')"
      >
        <div class="title size-16">{{ $t("协议前景") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/协议前景.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/愿景目标.png')"
      >
        <div class="title size-16">{{ $t("愿景目标") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/愿景目标.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/发展规划.png')"
      >
        <div class="title size-16">{{ $t("发展规划") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/发展规划.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/协议生态.png')"
      >
        <div class="title size-16">{{ $t("协议生态") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/协议生态.png"
          mode="aspectFill"
        />
      </div>
      <div
        class="item"
        @click="lookImg('https://s3.613protocol.com/613/2/商业模型.png')"
      >
        <div class="title size-16">{{ $t("商业模型") }}</div>
        <img
          src="https://s3.613protocol.com/613/2/商业模型.png"
          mode="aspectFill"
        />
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/utils/request";

export default {
  name: "ProjectDesc",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    lookImg(img) {
      window.open(img);
    },
    submit() {
      if (!this.email) return this.toast(this.$t("请输入您的邮箱"));
      http(
        "/api/web/receiveMaterial",
        {
          contact: this.email,
        },
        "POST"
      ).then((res) => {
        this.showToast(this.$t("提交成功"),'success');
        this.email = "";
      });
    },
  },
};
</script>

<style lang="scss">
.jieshoDetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    border: 1px solid #fff;
    border-radius: 0.2rem;
    overflow: hidden;
    padding: 0.2rem;
    cursor: pointer;
    margin-bottom: 0.2rem;

    .title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.2rem;
    }

    img {
      display: block;
      margin: auto;
      width: 2.5rem;
      height: 3.5rem;
      border-radius: 0.1rem;
      object-fit: cover;
    }
  }
}
.introduce {
  padding-top: 2rem;
}
.introduce-inp {
  width: 100%;
  height: 0.64rem;
  border-radius: 0.1rem;
  background-color: rgba(31, 33, 31, 0.4);
  border: 1px solid #373937;
  padding: 0 0.14rem 0 0.3rem;
  .introduce-input{
    background-color: transparent;
    color: #FFFFFF;
  }
  .introduce-btn {
    font-weight: bold;
    background-color: #00e36f;
    color: #0e100e;
    text-align: center;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 0.1rem;
    padding: 0 0.3rem;
  }
}
@media (max-width:768px) {
    .jieshoDetail{
        .item{
            img{
                width: 1.7rem !important;
                height: 2.7rem !important;
            }
        }
    }
}

</style>