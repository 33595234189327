import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import "perfect-scrollbar/css/perfect-scrollbar.css";

/**
 * 全局常量
 */
import config from '@/config'
Vue.prototype.$config = config

/**
 * 页面通用重复代码混入
 */
import mixin from '@/mixins/index.js'
Vue.mixin(mixin)

/**
 * 使用Vuex
 */
import store from '@/store';
Vue.prototype.$store = store

/**
 * ElementUI
 */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueAnimateOnScroll from 'vue-animate-onscroll';
Vue.use(VueAnimateOnScroll)

import "animate.css";

/**
 * 国际化
 */
import messages from '@/locale'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: config.getLang(),
  messages
})
export function $t(key){
  return i18n.tc(key)
}

new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
