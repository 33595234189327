<template>
  <div class="main-padding">
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2"/>
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">{{
      $t("合作伙伴")
    }}</div>

    <div class="flex pc">
      <img
        src="../assets/images/partner1.png"
        mode="widthFix"
        class="flex-1 mr-26 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
      <img
        src="../assets/images/partner2.png"
        mode="widthFix"
        class="flex-1 mr-26 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
      <img
        src="../assets/images/partner3.png"
        mode="widthFix"
        class="flex-1 mr-26 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
      <img
        src="../assets/images/partner4.png"
        mode="widthFix"
        class="flex-1 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
    </div>
    <div class="flex mobile">
      <img
        src="../assets/images/partner1.png"
        mode="widthFix"
        class="flex-1 mr-26 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
      <img
        src="../assets/images/partner2.png"
        mode="widthFix"
        class="flex-1 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
    </div>
    <div class="flex mobile mt-26">
      <img
        src="../assets/images/partner3.png"
        mode="widthFix"
        class="flex-1 mr-26 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
        />
      <img
        src="../assets/images/partner4.png"
        mode="widthFix"
        class="flex-1 partner-img"
        v-animate-onscroll.repeat="'animate__animated animate__flipInY'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "OurPartner",
};
</script>

<style lang="scss">
.partner-img{
  width: 2.8rem;
  height: auto;
  max-width: 40vw;
}
</style>