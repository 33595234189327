<template>
  <div class="botbar size-12 mt-120">
    <!-- {{$t('Copyright')}} -->
    Copyright © 2024 613 Protocol All rights reserved.
  </div>
</template>

<script>
export default {
  name: "BotBar",
};
</script>

<style lang="scss">
.botbar {
  width: 100vw;
  padding: 0.2rem;
  text-align: center;
  color: #595b59;
  background-color: #191b19;
}
</style>